import React, { useState } from 'react';
import { BlogProps } from '.';

import Fade from 'react-reveal/Fade';
import Loadable from '@loadable/component';
import { useFullscreenCarousel } from '../../../support/carousel/fullscreen-carousel/fullscreen-carousel';
const ArticleSummary1 = Loadable(() => import(/* webpackPrefetch: true */ './1/article-summary'));
const ArticleSummary2 = Loadable(() => import(/* webpackPrefetch: true */ './2/article-summary'));
export const BlogRenderer = (props: BlogProps) => {
   const [revealed, setRevealed] = useState(false);
   const allImages = props.articles?.map((a) => a.frontmatter.image);
   const fullscreenApi = useFullscreenCarousel({ images: allImages });
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {fullscreenApi.fragment}
         {props.style === '1' ? (
            <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 " key={props.key}>
               {props.articles?.map((a, idx) => (
                  <Fade onReveal={() => setRevealed(true)}>
                     {revealed && <ArticleSummary1 key={idx} {...{ article: a.frontmatter, ...props, fullscreenApi }} />}
                  </Fade>
               ))}
            </div>
         ) : null}
         {props.style === '2' ? (
            <div className="flex flex-col grid-cols-1 gap-4 " key={props.key}>
               {props.articles?.map((a, idx) => (
                  <Fade onReveal={() => setRevealed(true)}>
                     {revealed && <ArticleSummary2 key={idx} {...{ article: a.frontmatter, ...props, fullscreenApi }} />}
                  </Fade>
               ))}
            </div>
         ) : null}
      </>
   );
};

export default BlogRenderer;
